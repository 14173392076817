<template>
  <div class="btn-group" :class="{'opacity-button': !isMe}">
    <button class="btn btnoutline dropdown-toggle" type="button" id="dropdownMenuClickableInside"
      data-bs-toggle="dropdown" data-bs-auto-close="outside" ref="destinationDropDown">

      <strong v-if="isFrom">
        {{$t("sabre.search-panel.from-where")}}
      </strong>
      <strong v-else>
        {{$t("sabre.search-panel.where-flying-to")}}
      </strong>
      <span><i class="fa-solid fa-location-dot"></i></span><small>{{ showingDestString || $t('sabre.search-panel.select-destination') }}</small>
    </button>

    <select-destination :isFrom="isFrom" :isMultiCity="isMultiCity" :propsClassName="dropdownClassName"
    type="dropdown" aria-labelledby="dropdownMenuClickableInside" @finished="closeDropdown"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'desktop-dropdown-checkbox',
  mixins: [dropdownOpenState],
  props: {
    isFrom: {
      type: Boolean,
      default: false,
    },
    isMultiCity: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: 'from-destination',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      destinationList: 'GET_DESTINATION_IMAGES',
      chosenStoreDest: 'GET_SABRE_CHOSEN_DESTINATIONS',
      chosenMultiCityStoreDest: 'GET_SABRE_CHOSEN_MULTI_DESTINATIONS',
      countries: 'GET_SABRE_COUNTRIES',
    }),
    isLangCodeEn() {
      return this.$route.query?.langCode === 'ENG';
    },
    showingDestString() {
      const { destinations, chosenStoreDest, chosenMultiCityStoreDest, isFrom, isMultiCity, isLangCodeEn, countries } = this;
      const origin = (isMultiCity) ? chosenMultiCityStoreDest : chosenStoreDest;
      const dest = (isFrom) ? origin.from[0] : origin.to[0];
      if (!dest) return '';
      const current = destinations?.find((item) => item.code === dest) || '';
      const currentCountry = countries?.find((item) => item.code === current.countryCode)?.name_Loc?.content || '';
      return `${isLangCodeEn ? current.name : current?.name_Loc?.content || ''} - ${dest} - ${currentCountry}`;
    },
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    dropdownClassName() {
      const { showed, device } = this;
      return `dropdown-menu fromwheredropdown ${showed ? 'show' : ''}${device === 'mobile' ? ' fixedbox' : ''}`;
    },
  },
  components: {
    selectDestination: () => import('./selectDestination'),
  },
  data: () => ({
    showed: false,
  }),
  methods: {
    closeDropdown() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.destinationDropDown).hide();
      this.$emit('closed', this.componentName);
      this.showed = false;
    },
    openDropdown() {
      this.showed = true;
    },
  },
};
</script>
